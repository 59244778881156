import * as React from "react"
import Layout from "../components/layout"
import { Col } from "react-bootstrap"
import Seo from "../components/seo"
import "./support.scss"


const DonatePage = () => {
  return (
    <Layout>
      <Seo title="Support us" />
      <div className="contentalign">
        <div className="justify-content-ms-center row">
          <h1 style={{textAlign: 'center'}}>Thank you for visiting Iwant2explore</h1>        
          <div className="image-block row" style={{justifyContent: 'center'}}>
            <div className="col-lg-6" >
              <img src="https://cdn.iwant2explore.com/gallery/donate1682679054831.jpg.webp" alt="donate" />                
            </div>
          </div>
          <div className="contentblock">
            <h2></h2>
            <div>
              <p style={{textAlign: 'center'}}>
                <span style={{fontSize: '14pt'}}>If you have found our website helpful in planning your travels, we would be honored if you can support us. </span>
              </p>
              <p style={{textAlign: 'center'}}>
                <span style={{fontSize: '14pt'}}>We have spent sizeable amount in building the website, a lot of it has gone into development of the website plus Itinerary building</span>
              </p>
            <h2 style={{textAlign: 'center'}}><strong>You may click on the the button below to support our work.</strong></h2>
            <h2 style={{textAlign: 'center'}}>
              <a className="btn btn-primary btn-md" href="https://razorpay.me/@ambitio">Support us</a>
            </h2>
            <p style={{textAlign: 'center'}}><span style={{backgroundColor: '#fbeeb8', fontSize: '14pt'}}><strong>Ambitio is our Firm Name for Visa2Explore and Iwant2Explore</strong></span></p>
            <p style={{textAlign: 'center'}}>&nbsp;<span style={{fontSize: '14pt'}}>You will be redirected to a secure payment page where you can enter the amount you would like to support. </span></p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '14pt'}}>We accept all popular payment modes.&nbsp;</span></p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '14pt'}}>
                Hope that you found the Itineraries useful and these will help you make better travel plans. If you have any feedback or suggestion for us, Please feel free to write back to us through contact page.
              </span></p></div></div>
        </div>
      </div>
    </Layout>)
}

export default DonatePage

